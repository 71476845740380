<template>
    <div class="tailBox">
        <div class="tail">
            <footer>
                <span class="about fs"><a class="jump fs" @click="linkToAbout">关于我们</a></span>
                <span class="cur"></span>
                <span class="about fs">加入我们</span>
                <span class="cur"></span>
                <span class="about fs">联系我们</span>
            </footer>
            <div class="off"></div>
            <div class="unif_info">
                <span class="work fs">工作时间：周一至周五 9:00-18:00</span>
                <span class="business">
                    <span class="cooperation fs">公司邮箱: zaijiaxue@zjx.com</span>
                </span>
            </div>
        </div>
        <div class="corporation">
            <div class="corporation_info">
                <p class="full_info fs">
                    Copyright@北京再佳学教育科技有限公司版权所有 地址: 北京市朝阳区金台西路甲2号
                    <a class="full_info" href="http://www.beian.miit.gov.cn">
                        京ICP备18046952号-1
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'footerText',
        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {
            linkToAbout(){
                this.$router.push('/about')
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../assets/css/footer.css';
</style>
