<template>
    <div class="content">
        <header>
            <div class="header_left">
                <div>
                    <img class="logo" src="../assets/img/zuimeikebenlogo.png" alt="">
                </div>
                <span class="line"></span>
                <span class="platform fs">
                    美育融合化学习平台
                </span>
            </div>
            <div class="header_content">
                <ul class="uls">
                    <li class="fs" @click="linkToPage(item.id, index)" v-for="(item, index) in navList" :key="index">
                        {{item.name}}
                        <p :class="[item.url == $route.path || ($route.path.indexOf('newDetailsOne') > -1 && index == 4) ? 'line_bottom' : '']"></p>
                    </li>
                </ul>
            </div>
        </header>
    </div>
</template>

<script>

    export default {
        name: 'headerText',
        data() {
            return {
                navList: [
                    { id: 1,  name: '首页', url: '/' },
                    { id: 2,  name: '最美课本', url: '/classroom' },
                    { id: 3,  name: '教育公益', url: '/education' },
                    { id: 4,  name: '合作共赢', url: '/win' },
                    { id: 5,  name: '公司新闻', url: '/companyNews' },
                    { id: 6,  name: '关于我们', url: '/about' },
                    { id: 8,  name: '教师登录', url: '' },
                ],
                current: 0,
            };
        },
        mounted() {

        },
        methods: {
            linkToPage(val, index){
                this.current = index;
                if(val == 1){
                    this.$router.push('/')
                }else if(val == 2){
                    this.$router.push('/classroom')
                }else if(val == 3){
                    this.$router.push('/education')
                }else if(val == 4){
                    this.$router.push('/win')
                }else if(val == 5){
                    this.$router.push('/companyNews')
                }else if(val == 6){
                    this.$router.push('/about')
                }else if(val == 7){
                    this.$router.push('/teacher')
                }else if(val == 8){
                    window.open('https://teacher.zjx.com/login');
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../assets/css/header.css';
</style>
